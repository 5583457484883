#root{
    width: 100%;
}

html, body, .ui.grid, .ui.grid.row {
    width: 100%;
    height: 100%;
    padding:0;
    margin:0;
}

.ui.container{
    width: 100%;
    max-height: 100%;
    padding:0;
    margin:0;
}

.container {
max-width: 100%;
max-height: 100%;
}

.image{
    height: 200px;
    margin: 0 auto;
    
}

.func-img{
    max-height: 70% !important;
    max-width: 70% !important;
}

#container-mapinfo, #container, #root {
    width: 100%;
    height: 100%;
}

.leaflet-container {
    width: 100%;
    min-height: 94%;
    margin: 0;
    padding:0;
}
.leaflet-container {
    height : 700px;
    width: 100%;
  }
.sr-only {
    display: none;
}

.noPadding {
    padding-left: 0;
    padding-right: 0;
}

.SpecieInfoPadding {
    padding-right: 0;
}
.noMargin {
    margin: 0 !important;

}
.dot {
    height: 30px;
    width: 30px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }


  .list-group{
    max-height: 58.7vh;
    margin-bottom: 10px;
    overflow-y:scroll;
    -webkit-overflow-scrolling: touch;
}

.SummaryMapCol{
    box-shadow: -12px 0 8px -4px rgba(31, 73, 125, 0.8);
}

.AreaBox {
	border: none;
	padding: .5em 1em;
    margin: 0 auto;
}

.AreaBox-title {
	margin: 0;
	color: white;
	font-size: 1rem;
    font-weight: normal;
    margin: 0 auto;
}

.AreaBox-value {
	color: white;
	font-size: 1rem;
}

#mapContent {
    padding-top: 62px;
}

.graph_container {
    display: inline-block;
}

/* The emerging W3C standard
   that is currently Firefox-only */
   * {
    scrollbar-width: thin;
    scrollbar-color: gray white;
  }
  
  /* Works on Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }
  *::-webkit-scrollbar-track {
    background: white;
  }
  *::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 20px;
    border: 3px solid white;
  }

  .map-container {
    height : 80vh;
    width: 100%;
    margin-top: 1px;
    justify-content: center;
    border-radius: 5px;
  }

  #menu_layers {
    position: absolute;
    background: #fff;
    padding: 10px;
    font-family: 'Open Sans', sans-serif;
    }

    .center{
        text-align: center;
        display: block;
        justify-content: center;
        align-items: center;
        margin: auto;
        /* width: 100%; */
      }

      .features-rating-container{
        display: flex; 
        flex-wrap: wrap;
        justify-content: space-evenly;
      }
      
      .rating-divs {
        flex-basis: 45%; 
      }

      .rightAlign {
        display: flex;
        justify-content: right;
      }

      .mapboxgl-ctrl-group button {
        width: 45px;
      }
      .center_graph_display{
        display: flex;
        justify-content: center;
      }
      @media screen and (min-width:1281px) {
        .graph_display{
          display: flex;
          justify-content: center;
        }
        .test-chart{
          width: 300px;
        }
    }

    .list_groups .ui.horizontal.list>.item {
      margin-left : 0;
    }

    .list_groups .ui.horizontal.relaxed.list .list>.item:not(:first-child), .ui.horizontal.relaxed.list>.item:not(:first-child) 
    {
      Padding-left : 0;
    }


    