.Box {
	background: white;
	border: 1px solid #F3F3F3;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
	padding: 1em;
	position: relative;
}

.Box--blue {
	background: lighten(#0E9AA7, 10%);
}

.Box--red {
	background: lighten(#e74c3c, 5%);
}

.Box--green {
	background: lighten(#16ab39, 5%);
}

.Box--lime {
	background: lighten($color: #CDDC39, $amount: 5%);
}

.Box--orange {
	background: lighten(#e67e22, 10%);
}

.Box--1A {
	background: #9BBB58;
}

.Box--1B {
	background: #D8E7C0;
}

.Box--2A {
	background: #315F90;
}

.Box--2B {
	background:  #4F81BE;
}

.Box--2C {
	background: #B7CCE7;
}
.Box--3A {
	background: #F9984B;
}

.Box--3B {
	background: #FCD5B5;
}

.Box--4A {
	background:#FFC000;
}

.Box--4B {
	background: #FFD879;
}

.Box--5 {
	background: #F2F300;
}
.Box--undef {
	background: #000;
}

.Box-settings {
	position: absolute;
	top: 10px;
	right: 15px;

	button {
		color: #ABABAB;
		font-size: 0.8em;

		&:hover { color: #666; }
	}
}

.SummaryBox {
	border: none;
	padding: .5em 1em;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.SummaryBox-title {
	margin: 0;
	color: black;
	font-size: 1rem;
	font-weight: normal;
}

.SummaryBox-desc {
	margin: 1;
	color: white;
	font-size: 11px;
	font-weight: normal;
}

.SummaryBox-value {
	color: black;
	font-size: 1.6rem;
}

.SummaryBox-icon {
	position: absolute;
	right: 10px;
	bottom: 10px;
	color: rgba(255, 255, 255, 0.8);
	font-size: 1.3rem !important;
}

.ChartBox {
	padding: 0 .5em 1em;
}

.ChartBox-title {
	margin: .5em .5em !important;
	color: #666;
	font-size: 1.2rem;
	text-align: center;
  border-bottom: 1px dashed #EAEAEA;
  padding-bottom: 0.6em;
}